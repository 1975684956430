import * as React from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import global from '../../../classes/global';
import Grid from '@material-ui/core/Grid';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import CancelIcon from '@material-ui/icons/Cancel';
import { TagBox } from 'devextreme-react/tag-box';
import Accordion from 'devextreme-react/accordion';
import AlertDialog from '../../common/alertDialog';
import DeleteDialog from '../../common/DeleteAlert';
import Spinner from '../../common/reusableComponents/Spinner';
import SelectBoxInput from '../../common/reusableComponents/SelectBox';
import CheckBoxInput from '../../common/reusableComponents/CheckBox';
import TextBoxInput from '../../common/reusableComponents/TextBox';
import TextAreaInput from '../../common/reusableComponents/TextArea';
import NumberBoxInput from '../../common/reusableComponents/NumberBox';
import capitalize from '@material-ui/core/utils/capitalize';
export default class AddFormQuestionConfiguration extends React.Component<any, any> {

    public worktypeItems: any = [];

    constructor(props: any) {
        super(props);

        this.addItem = this.addItem.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.selectionChanged = this.selectionChanged.bind(this);
        this.CustomItem = this.CustomItem.bind(this);
        this.CustomTitle = this.CustomTitle.bind(this);
        this.worktypeItems = [];
        this.state = {
            active: true,
            Sequenceno: 0,
            Condition: '',
            choices: '',
            DefaultValue: '',
            formType: '',
            selectedworktypeItems: [],
            workTypeFieldsItems: this.props.items,
            allworkTypeFieldsItems: this.props.items,
            collapse: true,
        };
    }
    public didMount() {
        this.getFormTypes();
        this._getworkTypeDataAsync();
        this._getFieldItems();
        this.setState({
            workTypeFieldsItems: this.props.items,
            allworkTypeFieldsItems: this.props.items,
            hideAlertButton: false,
            selectedfieldItems: [],
            mode: this.props.mode
        })
    }
    private async _getFieldItems() {
        let dataToPassToService: any = {};
        try {
            const fieldsData: any = await utility.genericGetAPICallCompressed(Config.FieldsApiCompressed, 'POST', dataToPassToService);

            if (fieldsData) {
                let _items = [];
                _items = JSON.parse(fieldsData.Content);
                _items = _items.map((item: any) => ({
                    ...item,
                    id: item.id,
                    text:item.title,
                    isActive: item.isActive ? 'Yes' : 'No',
                }));
                const sortedItems = utility._sortItems(_items, 'text', false);
                this.setState({ fieldItems: sortedItems, allfieldItems: sortedItems, callAfterInit: true });

            }
        } catch (err) {
            console.log(err);
            this.setState({ loading: false });
        }

    }
    private _getworkTypeDataAsync() {
        utility.genericGetAPICall(Config.WTlistname).then((data: any) => {
            this.worktypeItems = [];
            let tempWorkTypeId = this.props.details && this.props.details.worktypes;

            for (let r of data) {
                let findWorkTypeId = tempWorkTypeId && tempWorkTypeId.find((wo: any) => wo.Id === r.Id);

                if (!findWorkTypeId) {
                    this.worktypeItems.push({
                        id: r.Id,
                        text: (r.Title + ' - ' + r.Code),
                    });
                }
            }
            this.setState({
                worktypeItems: this.worktypeItems,
                allworktypeItems: this.worktypeItems
            });
        }, (err) => {
            console.log(err);
        });
    }
    private async getFormTypes() {
        const data: any = await utility.genericGetAPICallForList(Config.FormTypesApi, 'GET');
        const _items = data.filter((f: any) => f.isActive).map((r: any) => ({
            id: r.id,
            text: r.title
        }));
        const sortedItems = utility._sortItems(_items, 'text', false);
        this.setState({
            formTypeItems: sortedItems,
            allformTypeItems: sortedItems
        });

    }
    public addItem() {
        let dataToPassToService: any = {};
        if (!this.state.formType) {
            this.setState({
                alertMessage: 'Please select Form Type',
                showAlert: true
            });
            return;
        }
        if (!this.state.woworktype) {
            this.setState({
                alertMessage: 'Please select Activity',
                showAlert: true
            });
            return;
        }

        if (!this.state.selectedfieldItems || this.state.selectedfieldItems.length < 0) {
            this.setState({
                alertMessage: 'Please select Field',
                showAlert: true
            });
            return;
        }
        if (this.state.selectedfieldItems) {
            let validate = true;
            this.state.selectedfieldItems.forEach((item: any) => {

                if (!validate) {
                    return;
                }
                validate = this.checkWorkTypeItemValidation(item);


            });
            if (!validate) {
                this.setState({
                    alertMessage: `Please fill incomplete fields in Field Options`,
                    showAlert: true
                });
                return false;
            }
        }
        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
            hideAlertButton: true,
            showAlert: true
        });
        let fields = [];
        if (this.state.selectedfieldItems.length > 0) {
            fields = this.state.selectedfieldItems.map((f: any) => {
                return {
                    FieldId: f.id,
                    Choices: f.choices,
                    Sequence: f.Sequenceno,
                    Conditions: f.conditions,
                    Required: f.IsRequired,
                    IsMultiple: f.IsMultiple,
                    DefaultValue: f.defaultValue,
                    erpField: f.erpField,
                    erpTable: f.erpTable,
                }
            }
            );
        }
        dataToPassToService.FormTypeId = this.state.formType.id;
        dataToPassToService.WorkorderTypeIds = this.state.woworktype;
        dataToPassToService.Fields = fields;
        dataToPassToService.Usercreated = this.props.currentUser.Email;
        dataToPassToService.Userupdated = this.props.currentUser.Email;
        dataToPassToService.Tenantid = Config.tenantId;
        utility.genericPostAPIcall(Config.createBulkWorkOrderTypeRelationAPI, dataToPassToService).then((data: any) => {
            //console.log(data);
            this.setState({ showAlert: false });
            this.clearFilter();
            this.props.onClick();
            this.props.message("Form Configuration has been created");
        }, (err: any) => {
            console.log(err);
            this.setState({
                alertMessage: 'Something went wrong!',
                hideAlertButton: false,
                showAlert: true
            });
        });
        utility.removeItem(Config.workTypeFieldList)
    }

    public clearFilter(clearfield?: any, title?: any) {

        this.setState({
            type: '',
            woworktype: '',
            mode: clearfield ? 'add' : 'edit'
        });
        if (!clearfield)
            this.props.onClose();

    }
    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    public handleDropdownChange(e: any, fieldName: string) {
        if (e.selectedItem) {
            this.setState({ [fieldName]: e.selectedItem });
        }
    }
    public _onFieldSelect = (e: any, fieldName: string, options?: any, isFieldOption?: any) => {

        if (isFieldOption) {
            this.setState({
                title: e.selectedItem && e.selectedItem.title ? { id: e.selectedItem.title, text: e.selectedItem.title } : e.selectedItem
            });
            if (this.state[options] && (e.selectedItem && this.state[options].some((s: any) => s.title === e.selectedItem.title))) {
                let selectedField = this.state[options].find((s: any) => s.title === e.selectedItem.title);
            } else {
                this.clearFilter(true, e.selectedItem && e.selectedItem.title);
            }
        } else {
            let fieldItems: any = this.state.fieldItems;
            let selectedfieldItems: any = this.state.selectedfieldItems ? this.state.selectedfieldItems : [];
            if (e.selectedItem) {
                let findIndex: any = fieldItems.findIndex((wo: any) => wo.id === e.selectedItem.id);

                // worktypeItems.
                fieldItems.splice(findIndex, 1);
                selectedfieldItems.push({
                    defaultValue: e.selectedItem.defaultValue,
                    erpField: e.selectedItem.erpField,
                    erpTable: e.selectedItem.erpTable,
                    isGlobal: e.selectedItem.isGlobal,
                    isActive: e.selectedItem.isActive,
                    fieldType: e.selectedItem.fieldType,
                    conditions: e.selectedItem.condition,
                    Choices: e.selectedItem.choices,
                    id: e.selectedItem.id,
                    IsRequired: e.selectedItem.required,
                    title: e.selectedItem.title,
                    IsMultiple: e.selectedItem.isMultiple,
                });

                this.setState({
                    fieldItems: fieldItems,
                    selectedfieldItems: selectedfieldItems,
                    loading: true,
                    type: e.selectedItem.fieldType ? e.selectedItem.fieldType : '',
                    choices: e.selectedItem.choices ? e.selectedItem.choices : '',
                });
                if (!this.state.selectedfieldItems || selectedfieldItems.length === 1) {
                    this.setState({
                        selectedItems: selectedfieldItems[0]
                    });
                }
                setTimeout(() => this.setState({ loading: false }), 500)
            }
            this.setState({ [fieldName]: e.selectedItem })
        }
    }

    public removeWorkType() {
        let fieldItems: any = this.state.fieldItems;
        let selectedfieldItems: Array<[]> = this.state.selectedfieldItems;

        fieldItems.push(this.state.removingWorkTypeItem);
        fieldItems = utility._sortItems(fieldItems, 'title', false);

        selectedfieldItems.splice(this.state.removingWorkTypeIndex, 1);
        this.setState({
            selectedfieldItems: selectedfieldItems,
            fieldItems: fieldItems,
            DeletealertMessage: '',
            showDeleteAlert: false,
            selectedItems: selectedfieldItems && selectedfieldItems.length > 0 ? selectedfieldItems[0] : [],
            selectedWorkTypeID: 0,
            loading: true
        });
        setTimeout(() => this.setState({ loading: false }), 1000)
    }
    public checkWorkTypeItemValidation(item: any) {

        let validate = false;
        if (item.Sequenceno &&  item.Sequenceno !== 0 && (item.fieldType === 'Choice' || item.fieldType === 'Radio' || item.fieldType === 'CheckBox') && (item.Choices && item.Choices.length > 0)) {
            validate = true;
        }
        if (item.Sequenceno &&  item.Sequenceno !== 0  && item.fieldType !== 'Choice' && item.fieldType !== 'Radio' && item.fieldType !== 'CheckBox') {
            validate = true;
        }
        return validate;
    }
    public selectionChanged(e: any) {

        let newItems: any = [];
        if (this.state.selectedItems) {
            newItems = [this.state.selectedItems]
        }

        e.removedItems.forEach((item: any) => {
            const index = newItems.indexOf(item);
            if (index >= 0) {
                newItems.splice(index, 1);
            }
        });
        if (e.addedItems && e.addedItems.length > 0) {
            newItems = e.addedItems;
        }
        this.setState({
            selectedItems: newItems,
        });
    }
    private _multiFieldChanged = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });

    }
    public render(): React.ReactElement<any> {
        return (
            <div>
                {this.state.showAlert && this.alert()}
                {this.state.showDeleteAlert && this.DeleteAlert()}
                <Popup
                    width={'80vw'}
                    maxHeight={'85vh'}
                    minHeight={200}
                    height={'auto'}
                    showTitle={true}
                    showCloseButton={true}
                    title={`${this.state.mode && capitalize(this.state.mode)} Form Question Configuration ${this.state.id ? "(" + this.state.id + ")" : ''}`}
                    dragEnabled={true}
                    hideOnOutsideClick={false}
                    visible={this.props.showModal}
                    onVisibleChange={() => this.clearFilter(false)}
                    onShowing={() => this.didMount()}

                >
                    <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>

                        <div className='popContentDiv'>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <SelectBoxInput
                                        stylingMode='underlined'
                                        displayExpr="text"
                                        valueExpr="id"
                                        showClearButton={true}
                                        items={this.state.formTypeItems}
                                        selectedItem={this.state.formType}
                                        label='Form Type *'
                                        onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'formType')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TagBox
                                        items={this.state.worktypeItems}
                                        value={this.state.woworktype ? this.state.woworktype : ''}
                                        label='Activity'
                                        showSelectionControls={true}
                                        searchEnabled={true}
                                        displayExpr="text"
                                        valueExpr="id"
                                        onValueChanged={(e: any) => this._multiFieldChanged(e, 'woworktype')}
                                        selectAllMode="allPages"
                                    />

                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <div className='workTypeListContainer' >
                                        <h3>Field Options</h3>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={12}>
                                                {!this.state.loading ?
                                                    <>
                                                        <SelectBoxInput
                                                            stylingMode='underlined'
                                                            displayExpr="text"
                                                            valueExpr="id"
                                                            showClearButton={true}
                                                            items={this.state.fieldItems}
                                                            selectedItem={''}
                                                            label='Fields'
                                                            onSelectionChanged={(e: any) => this._onFieldSelect(e, 'field', 'fieldItems')}
                                                        />

                                                        {(this.state.selectedfieldItems && this.state.selectedfieldItems.length > 0) &&
                                                            <>
                                                                <Accordion
                                                                    dataSource={this.state.selectedfieldItems}
                                                                    collapsible={this.state.collapse}
                                                                    multiple={false}
                                                                    animationDuration={300}
                                                                    selectedItems={this.state.selectedItems}
                                                                    onSelectionChanged={this.selectionChanged}
                                                                    itemTitleRender={this.CustomTitle}
                                                                    itemRender={this.CustomItem}
                                                                />
                                                            </>
                                                        }
                                                    </> :
                                                    <Spinner size='large' label="Loading..." />
                                                }

                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </ScrollView>

                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'close',
                            text: 'Cancel',
                            stylingMode: "outlined",
                            onClick: () => this.clearFilter(false),
                        }}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'save',
                            text: 'Save',
                            stylingMode: "contained",
                            onClick: this.addItem,
                        }}
                    />
                </Popup>
            </div >
        );
    }
    public CustomTitle(data: any, i: any) {

        return (
            <div style={{ width: '100%' }} className='displayFlex'>
                <div className='workTypeListItemTitle'> {data.title}</div>
                <div className='displayFlex' style={{ float: 'right', marginRight: '25px', width: '47%', flexDirection: 'row', justifyContent: 'end' }}>
                    <div style={{ width: 'fit-content' }} className={this.checkWorkTypeItemValidation(data) ? 'validatedworkTypeListItem' : 'workTypeListItem'}>{this.checkWorkTypeItemValidation(data) ? "Validated" : "Please validate all items"}</div>
                    <div style={{ marginLeft: '5px' }}><CancelIcon onClick={() => this.DeleteDialog(i, data)} /></div>
                </div>
            </div>
        );
    }
    private handleItemValueChange = (event: any, item: any, fieldName: string) => {
        const updatedItems = this.state.selectedfieldItems.map((fieldItem: any) => {
            if (fieldItem.id === item.id) { // Use unique ID to identify the item
                return { ...fieldItem, [fieldName]: event.value };
            }
            return fieldItem;
        });

        const sortedItems = updatedItems.sort((a: any, b: any) => a.sequence - b.sequence);
        this.setState({ selectedfieldItems: sortedItems, collapse: false });
    };
    public CustomItem(data: any) {
        return (
            <div>
                <Grid container spacing={3}>
                    {(this.state.type === 'Choice' || this.state.type === 'Radio' || this.state.type === 'CheckBox') && (
                        <Grid item xs={12} sm={8}>
                            <TextAreaInput
                                stylingMode='underlined'
                                label="Choices *"
                                value={data.choices ? data.choices : this.state.choices || ''}
                                onValueChanged={(e: any) => this.handleItemValueChange(e, data, 'choices')}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <TextBoxInput
                            stylingMode='underlined'
                            label="ERP Table Name"
                            value={data.erpTable || ''}
                            onValueChanged={(e: any) => this.handleItemValueChange(e, data, 'erpTable')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <TextBoxInput
                            stylingMode='underlined'
                            label="ERP Field Name"
                            value={data.erpField || ''}
                            onValueChanged={(e: any) => this.handleItemValueChange(e, data, 'erpField')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <NumberBoxInput
                            stylingMode='underlined'
                            label="Sequence # *"
                            value={data.Sequenceno || ''}
                            onValueChanged={(e: any) => this.handleItemValueChange(e, data, 'Sequenceno')}
                            min={0}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <TextBoxInput
                            stylingMode='underlined'
                            label="Condition"
                            value={data.Condition || ''}
                            onValueChanged={(e: any) => this.handleItemValueChange(e, data, 'Condition')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <TextBoxInput
                            stylingMode='underlined'
                            label="Default Value"
                            value={data.DefaultValue || ''}
                            onValueChanged={(e: any) => this.handleItemValueChange(e, data, 'DefaultValue')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <CheckBoxInput
                            value={!!data.IsRequired}
                            onValueChanged={(e: any) => this.handleItemValueChange(e, data, 'IsRequired')}
                            text="Is Required"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <CheckBoxInput
                            value={!!data.IsMultiple}
                            onValueChanged={(e: any) => this.handleItemValueChange(e, data, 'IsMultiple')}
                            text="Is Multiple"
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
    public DeleteDialog(i: any, item: any) {
        this.setState({
            DeletealertMessage: 'Are you sure you want to delete?',
            showDeleteAlert: true,
            removingWorkTypeIndex: i,
            removingWorkTypeItem: item,
        });
    }
    public DeleteAlert() {
        return (
            <DeleteDialog
                showDialog={this.state.showDeleteAlert}
                message={this.state.DeletealertMessage}
                title={"worxOnline"}
                onClose={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
                onOkButton={() => this.removeWorkType()}
                onCancelButton={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
            />
        );
    }

}
